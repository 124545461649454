'use client'
import React, { useState, useEffect, useMemo } from 'react'
import Heading from './Heading'
import SubHeading from './SubHeading'
import ProductCard from '../../ProductCard'
import useCustomSWR from '@/hook/useCustomSWR';
import { Skeleton } from '@mui/material';
import { useInView } from "react-intersection-observer";
import Link from 'next/link'
import Slider from '@/components/Slider'
const BrandTemplate1 = ({ data }) => {
  const { heading, sub_heading, ids } = data;
  const [selectedId, setSelectedId] = useState(ids[0]?.id)
  const brandIds = JSON.stringify(ids?.map(d => d.id))
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '500px 0px',
    threshold: 0,
  });

  const { data: listData, isLoading } = useCustomSWR(`/v1/cms/get-filter-content?filter_type=brand&ids=${brandIds}&limit=20`, inView)
  const { data: productData, isLoading: productLoading } = useCustomSWR(`/v1/products/get-all-products?filter[brand_id][$eq]=${selectedId}&random=yes&page=1&limit=5&stock_product=true`, inView)

  const handleChange = (id) => {
    setSelectedId(id)
  }
  const { aboutBanner, selectedName, slug } = useMemo(() => {
    if (!listData?.response?.data && !selectedId) return {};
    const banner = listData?.response?.data?.find((item) => item._id === selectedId);
    return { aboutBanner: banner?.about_banner, selectedName: banner?.name, slug: banner?.slug };
  }, [listData?.response?.data, selectedId]);
  if (!inView) return <div ref={ref}></div>;
  return (
    <div
      id='brand'
      ref={ref} className='flex flex-col scroll-offset'
    >
      <div className='md:py-3 lg:py-5'>
        <Heading text={heading} />
        <SubHeading text={sub_heading} />
      </div>
      <div className='max-w-6xl  w-full sm:justify-center mx-auto'>
        <Slider
          padding='px-4 md:px-8'
          gap={' sm:gap-2 md:gap-3'}
        >
          {
            listData?.response?.data?.map(c => (
              <BrandHeadingCard
                data={c}
                id={selectedId}
                handleChange={handleChange}
              />
            ))
          }
        </Slider>
      </div>
      <div className='home-product-section'>
        {productLoading
          ? Array.from({ length: 6 }, (_, index) => (
            <div className='h-52 w-full overflow-hidden rounded-lg md:h-96 2xl:h-[30rem]'>
              <Skeleton
                animation='wave'
                variant='rectangular'
                width='100%'
                height='100%'
              />
            </div>
          ))
          : null}
        {
          aboutBanner ? (
            <div className='h-52 overflow-hidden md:h-96 2xl:h-[30rem]'>
              <img
                src={aboutBanner}
                alt='Banner'
                className='aspect-square h-full w-full object-contain'
              />
            </div>
          ) : null
        }


        {productData?.productList &&
          productData?.productList?.map((product, index) => {
            return (
              <ProductCard
                key={product._id}
                product={product}
                style={'w-full h-52 md:h-96 2xl:h-[30rem]'}
                iconStyle={`w-5 h - 5 md: w - 7 md: h - 7`}
                textSize={`text - xs md: text - sm`}
                quickViewWidth={24}
                quickViewHeight={24}
              />
            );
          })}

      </div>
      <div className='mt-5 flex flex-col items-center justify-center py-2'>
        {
          <Link
            href={`/brand/${slug}`}
            className='flex w-fit items-center gap-2 rounded px-4 text-center text-base md:text-lg font-normal text-gray-700'
          >
            {` Explore ${selectedName} --> `}
          </Link>
        }

        <Link
          href={`/brand`}
          className='flex w-fit items-center gap-2 rounded px-4 text-center text-base md:text-lg font-normal text-gray-700'
        >
          {`Explore all brand -->`}
        </Link>
      </div>
    </div>
  )
}

export default BrandTemplate1

const BrandHeadingCard = ({ handleChange, id, data }) => {
  const { _id, name, image } = data;
  return (
    <div
      className={`relative h-16 w-16 shrink-0 transform cursor-pointer overflow-hidden rounded-full transition-transform duration-300 ease-in-out hover:scale-105 hover:opacity-90 hover:shadow-lg xl:h-20 xl:w-20 ${id == _id ? 'ring-2 ring-gray-400 ' : 'grayscale filter border'} overflow-hidden m-1`}
      onMouseOver={() => handleChange(_id)}
    >
      <div className='h-full w-full'>
        <img
          loading='lazy'
          width={100}
          height={100}
          src={image}
          alt={name}
          className='h-full w-full object-cover'
        />
      </div>
    </div>
  )
}